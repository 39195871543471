import JsSIP from 'jssip';
import {DevicePermissions} from './devicePermissions';
import {stopRingAudio} from './audioUtil';

class InitWebrtc {
    constructor(props,emit) {
        this.emit = emit
        this.props = props;

        this.hangUpSetInterval = null
        const {url: wsuri, sipAddr: sipuri, password: sippassword} = props.baseInfo;
        this.wsuri = wsuri
        this.sipuri = sipuri;
        this.currentSession = null;
        this.sippassword = sippassword;
        this.userAgent = null;
        this.isStart = false
    }


    /**
     * 初始化
     */
    init() {
        if (!this.wsuri) {
            return;
        }
        let socket = new JsSIP.WebSocketInterface(this.wsuri);
        socket.via_transport = 'ws';
        var configuration = {
            sockets: [socket],
            outbound_proxy_set: this.wsuri,
            uri: this.sipuri,
            password: this.sippassword,
            register: true,
            register_expires: 60 * 60 * 24,
            session_timers: true,
            no_answer_timeout: 60 // 默认值60
        };
        let userAgent = new JsSIP.UA(configuration);
        this.userAgent = userAgent;
        // websocket 连接成功


        //传输链接尝试
        userAgent.on('disconnected',  (e)=> {
            userAgent.stop()
        });

        // 注册成功
        userAgent.on('registered', async  (data)=> {
            // this.emit('status','registered')
            await this.props.connectCall()



        });
        // 注册失败
        userAgent.on('registrationFailed', (data)=> {
            stopRingAudio()
            this.emit('error',10010)
        });

        // 注册超时
        userAgent.on('registrationExpiring', ()=> {
            stopRingAudio()
            this.emit('error',10010)
        });


        userAgent.on('newRTCSession', (data) => {
            setTimeout(()=>{
                this.answerCall(data)
            },0)


            this.currentSession = data.session;

            // 通话结束
            data.session.on('ended',  (data)=> {
                stopRingAudio()
                this.emit('status','ended')

            });

            data.session.on('peerconnection',  (_data)=> {
                let audio = document.getElementById('wizAudio');
                if (!audio) {
                    audio = document.createElement('audio')
                    audio.id = 'wizAudio'
                    audio.playsinline = true
                    audio.autoplay = true
                    document.body.append(audio)
                }

                _data.peerconnection.onaddstream = function (ev) {
                    audio.srcObject = ev.stream;
                };
            });
        });

        userAgent.on('newMessage',  (data)=> {

            if(!this.isStart){
                this.mute();
                this.emit('status','start')
                const body = JSON.parse(data.request.body)
                stopRingAudio()
                this.emit('message',body)
                this.isStart = true;
            }else{
                const body = JSON.parse(data.request.body)
                stopRingAudio()
                this.emit('message',body)
            }
        });
        userAgent.start();
    }


    // 接听动作
    answerCall(data) {
        let devicePermissions = new DevicePermissions()
        data.session.answer({
            mediaConstraints: {
                audio: {
                    sampleRate: 16000
                },
                video: false,
                mandatory: {
                    maxWidth: 640,
                    maxHeight: 360
                }
            },
            mediaStream: devicePermissions.audioStream,
            sessionTimersExpires: 1800
        });
    }

    // 挂断动作
    terminalCall() {
        stopRingAudio()
        this.userAgent && this.userAgent.terminateSessions();
        this.userAgent.stop()

    }

    // 禁音
     mute() {
        const pc = this.currentSession.connection;
        if (pc.getSenders) {
            pc.getSenders().forEach(sender => {
                // 如果是视频的话 kind === 'video'
                if (sender.track.kind === 'audio') {
                    sender.track.enabled = false;
                }
            });
        } else {
            pc.getLocalStreams().forEach(stream => {
                stream.getAudioTracks().forEach(track => {
                    // 如果是视频的话 kind === 'video'
                    if (track.kind === 'audio') {
                        track.enabled = false;
                    }
                });
            });
        }
    }

    //发送DTMF
    sendDTMF(text,options){
        this.currentSession.sendDTMF(text,options)
    }

    //取消静音
     unmute() {
         const pc = this.currentSession.connection;
        if (pc.getSenders) {
            pc.getSenders().forEach(function (sender) {
                // 如果是视频的话 kind === 'video'
                if (sender.track.kind === 'audio') {
                    sender.track.enabled = true;
                }
            });
        } else {
            pc.getLocalStreams().forEach(function (stream) {
                stream.getAudioTracks().forEach(function (track) {
                    // 如果是视频的话 kind === 'video'
                    if (track.kind === 'audio') {
                        track.enabled = true;
                    }
                });
            });
        }
    }


}

export {
    InitWebrtc
}
