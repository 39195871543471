
function createRingAudio(ringSetting){
    let audioSrc = ''
    if(ringSetting.defaultRing){
        audioSrc = 'https://email-background.oss-ap-southeast-1.aliyuncs.com/2ring.wav'
    }else if(ringSetting.localRing){
         audioSrc = 'ring.mp3'
    }else if(ringSetting.ringUrl){
        audioSrc = ringSetting.ringUrl
    }
    let audio = document.getElementById('wizRingAudio');
    if (!audio) {
        audio = document.createElement('audio')
        audio.id = 'wizRingAudio'
        audio.playsinline = true
        audio.loop = true
        audio.autoplay = true
        audio.src=audioSrc;
        document.body.append(audio)
    }else{
        audio.play();
    }
}

function  stopRingAudio(){
    let audio = document.getElementById('wizRingAudio');
    if(audio){
        audio.pause();
        audio.currentTime = 0; // 重置音频到0秒
    }
}


export {stopRingAudio,createRingAudio}
