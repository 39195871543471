import {GetBaseInfo, InitWebrtc, DevicePermissions} from './mod';
import Event from './classEvent'
import {stopRingAudio} from "./audioUtil";

const options = {
    'duration': 50,
    'interToneGap': 50,
    'transportType': 'RFC2833'
};

export class Index extends Event {
    constructor(url) {
        super();
        this.webrtc = null;
        this.httpUrl = url
        this.pressTimer = null;
        this.isLongPress = false;
        this.baseService = new GetBaseInfo(this.emit);

        this.devicePermissions = new DevicePermissions(this.emit);
    }


    removeAllListeners() {
        super.off('on')
        super.off('message')
        super.off('status')
    }

    on(event, listener) {
        return super.on(event, listener)
    }

    emit = (type, params) => {
        super.emit(type, params)
    }

    static init(initInfo = {}) {
        if (!initInfo.url) {
            throw 'Please check the required parameter url'
            return;
        }


        if (!this.instance) {
            this.instance = new Index(initInfo.url);
        }
        this.instance.removeAllListeners()
        return this.instance;
    }

    /**
     * sdk启动入口
     */
    async start(initData = {}) {
        localStorage.removeItem('isEnd')
        if (!initData.accountCode) {
            throw 'Please check the required parameter accountCode'
            return;
        }
        if (!initData.tokenInfo) {
            throw 'Please check the required parameter tokenInfo'
            return;
        }
        if (!initData.tokenInfo.userId) {
            throw 'Please check the required parameter tokenInfo.userId'
            return;
        }
        if (!initData.tokenInfo.timestamp) {
            throw 'Please check the required parameter tokenInfo.timestamp'
            return;
        }
        if (!initData.tokenInfo.trainingToken) {
            throw 'Please check the required parameter tokenInfo.trainingToken'
            return;
        }

        if (initData.callLog && !initData.templateCode) {
            throw 'Please check the required parameter templateCode'
            return;
        }


        await this.devicePermissions.initCreate();
        await this.baseService.init(initData, this.httpUrl)
        if (localStorage.getItem('isEnd')) {
            localStorage.removeItem('isEnd')
            stopRingAudio()
            return;
        }
        this.webrtc = new InitWebrtc(this.baseService, this.emit);
        await this.webrtc.init();
    }


    hangUp() {
        stopRingAudio();
        this.isLongPress = false;
        localStorage.setItem('isEnd', true)
        this.webrtc && this.webrtc.terminalCall();
    }


    mute() {
        this.webrtc && this.webrtc.mute();
        clearTimeout(this.pressTimer)
        if (this.isLongPress) {
            console.log('发送B')
            this.webrtc && this.webrtc.sendDTMF('B', options);
        }else{
            console.log('按键不足500ms')
        }
    }


    unmute(time) {

        this.isLongPress = false;
        this.webrtc && this.webrtc.unmute();
        this.pressTimer = setTimeout(() => {
            console.log('发送A')
            this.isLongPress = true;
            this.webrtc && this.webrtc.sendDTMF('A', options);

        }, time)
    }

}





