import md5 from "js-md5";
import {createRingAudio,stopRingAudio} from './audioUtil';
class GetBaseInfo {
    constructor(emit) {
        this.emit = emit
        this.baseInfo = {};
        this.initData = {}
        this.agentId = '';
        this.accountCode = null
        this.httpUrl = '';
    }

    postData(url, data, auth = false) {
        return fetch(this.httpUrl + url, {
            body: JSON.stringify(data), // must match 'Content-Type' header
            headers: {
                Authentication: localStorage.getItem('wizToken') || '',
                'content-type': 'application/json'
            },
            method: 'POST', // *GET, POST, PUT, DELETE, etc.
        })
            .then(response => response.json())
    }



    async init(initData, httpUrl = '') {
        //如果传入则需要振铃
        if(initData.ringSetting){
            createRingAudio(initData.ringSetting)
        }
        this.httpUrl = httpUrl;
        this.initData = initData;
        const {accountCode, phone,...otherData} = initData
        this.accountCode = accountCode
        return new Promise(async (resolve, reject) => {
            const tokenResult = await this.postData('/sdk/token', {
                client: 'crm',
                accountCode,
                params: {...otherData,phone},
                ...this.initData.tokenInfo
            })
            if(localStorage.getItem('isEnd')){
                localStorage.removeItem('isEnd')
                stopRingAudio()
                return;
            }
            if (tokenResult && tokenResult.code !== 200) {
                stopRingAudio()
                this.emit('error', tokenResult.code)
                reject(false)
                return
            }
            localStorage.setItem('wizToken', tokenResult.data.accessToken)
            const baseInfoResult = await this.postData('/sdk/getAgentInfo', {}, true);
            if(localStorage.getItem('isEnd')){
                localStorage.removeItem('isEnd')
                stopRingAudio()
                return;
            }
            if (baseInfoResult && baseInfoResult.code !== 200) {
                stopRingAudio()
                this.emit('error', baseInfoResult.code)
                reject(false)
                return
            }
            const {number, password, realm, id} = baseInfoResult.data
            this.agentId = id;
            const domain = realm && realm.split("://")[1].split(":")[0];
            const sipData = {
                sipAddr: 'sip:' + number + '@' + domain,
                password: password,
                url: realm,
                type: 'ended'
            }
            this.baseInfo = sipData;
            resolve(true)
        })

    }

    //这边需要主要的是在注册成功后才开始调用接口 否则无效
    async connectCall() {
        const data = {
            agentId: this.agentId,
            accountCode: this.accountCode
        };
        if(!this.initData.callLog){
            data.templateCode = this.initData.templateCode
            data.initiator = 'crm_client'
            data.params =  this.initData.params
        }
        const tokenResult = await this.postData('/sdk/startOnlineCall', {
            ...data
        }, true);
        if (tokenResult.code !== 200) {
            stopRingAudio()
            this.emit('error', tokenResult.code)
        }

    }

}

export {GetBaseInfo}

