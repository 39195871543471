let constraints = {
    video: false,
    audio: true
};

class DevicePermissions {

    constructor(emit) {
        this.audioStream = null;
        this.emit = emit;
    }

    initCreate() {
        return new Promise((resolve, reject) => {
            try {
                if (navigator && navigator.mediaDevices) {
                    navigator.mediaDevices.getUserMedia(constraints).then(async (e) => {
                        await this.gotStream(e, resolve)
                    }).catch((e) => {
                        this.logError(e, reject)
                    })
                } else if (navigator &&  navigator.getUserMedia) {
                    navigator.getUserMedia(constraints, this.gotStream, this.logError);
                } else if (navigator &&  navigator.webkitGetUserMedia) {
                    navigator.webkitGetUserMedia(constraints, this.gotStream, this.logError);
                } else if (navigator &&  navigator.mozGetUserMedia) {
                    navigator.mozGetUserMedia(constraints, this.gotStream, this.logError);
                } else if (navigator && navigator.msGetUserMedia) {
                    navigator.msGetUserMedia(constraints, this.gotStream, this.logError);
                }
            } catch (e) {
                this.emit('error', 10000)

            }
        })
    }

    async gotStream(e, resolve) {
        e.getTracks().forEach((track) => {
            track.stop()
        });
        let data = await navigator.mediaDevices.enumerateDevices()
        await this.getDeviceId(data);
        resolve(true)

    }

    async getDeviceId(devices) {
        // Try matching by ID first.
        // for (let i = 0; i < devices.length; ++i) {
        //     let device = devices[i];
        //     // console.log('zx:', device.kind + ': ' + device.label + ' id = ' + device.deviceId);
        // }
        // Now, the discovered deviceId can be used in getUserMedia() requests.
        let constraints = {
            audio: {
                sampleRate: 16000,
            },
            video: false
        };
        if (navigator.mediaDevices) {
            const stream = await navigator.mediaDevices.getUserMedia(constraints);
            let audioStream = stream.clone();
            let videoTracks = audioStream.getVideoTracks();
            for (let i = 0, len = videoTracks.length; i < len; i++) {
                audioStream.removeTrack(videoTracks[i]);
            }
            // 获取audioTracks
            let audioTracks = audioStream.getAudioTracks()
            let setting = audioTracks[0].getSettings()
            if (setting) {
                setting.sampleRate = 16000
            }
            const track = audioTracks[0];
            try {
                track.applyConstraints({
                    sampleRate: 16000
                })
            } catch (e) {
                console.log(e)
            }
            this.audioStream = audioStream;

        }

    }

    //准备就是获取权限失败
    logError(error,reject) {
        this.emit('error', 10000)
        reject('error')
    }

    //初始化获取权限


}

export {
    DevicePermissions
}
